// ** Reducers Imports
import layout from "./layout"
import navbar from "./navbar"
import auth from './reducers/auth'
import banner from './reducers/banner'
import general from './reducers/general'
import item from './reducers/item'
import product from './reducers/product'
import transaction from './reducers/transaction'

const rootReducer = { navbar, layout, auth, banner, general, product, item, transaction }

export default rootReducer
