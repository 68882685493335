import { handleActions } from 'redux-actions'

const initialState = {
  adminLogin: {
    requesting: false,
    error: null
  }
}

const authReducer = handleActions(
  {
    ADMIN_LOGIN_REQUEST: (state) => ({ ...state, adminLogin: { requesting: true } }),
    ADMIN_LOGIN_SUCCESS: (state) => ({
      ...state,
      adminLogin: {
        requesting: false
      }
    }),
    ADMIN_LOGIN_FAILED: (state, action) => ({
      ...state,
      adminLogin: {
        requesting: false,
        error: action.payload.errors ? action.payload.errors.map((x) => x.message).join(', ') : action.payload
      }
    })
  },
  initialState
)

export default authReducer
