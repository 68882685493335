import { handleActions } from 'redux-actions'

const initialState = {
  items: {
    list: [],
    paging: {},
    data: {},
    requesting: false,
    error: null
  },
  isLoading: {},
  stockList: {
    list: [],
    paging: {},
    data: {},
    error: null
  }
}


const setLoading = (state, payload, isLoading = true) => ({
  ...state,
  isLoading: {
    ...state.isLoading,
    [payload]: isLoading
  }
})


const reducer = handleActions(
  {
    PRODUCT_ITEMS_LOAD_START: (state, action) => setLoading(state, action.payload, true),
    PRODUCT_ITEMS_LOAD_END: (state, action) => setLoading(state, action.payload, false),
    GET_PRODUCT_ITEMS_SUCCESS: (state, action) => ({
      ...state,
      items: {
        list: action.payload.list,
        paging: action.payload.paging
      }
    }),
    GET_PRODUCT_ITEMS_FAILED: (state, action) => ({
      ...state,
      items: {
        error: action.payload.errors ? action.payload.errors.map((x) => x.message).join(', ') : action.payload
      }
    }),
    GET_PRODUCT_ITEM_STOCKS_SUCCESS: (state, action) => ({
      ...state,
      stockList: {
        list: action.payload.list,
        paging: action.payload.paging
      }
    }),
    GET_PRODUCT_ITEM_STOCKS_FAILED: (state, action) => ({
      ...state,
      stockList: {
        error: action.payload.errors ? action.payload.errors.map((x) => x.message).join(', ') : action.payload
      }
    })
  },
  initialState
)

export default reducer
