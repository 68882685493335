import { handleActions } from 'redux-actions'

const initialState = {
  metadata: {
    data: [],
    paging: {},
    requesting: false,
    error: null
  },
  uploadsImage: {
    requesting: false,
    error: null,
    url: ''
  }
}

const setMetadata = (state, payload) => {
  return {
    ...state,
    metadata: {
      ...state.metadata,
      ...payload
    }
  }
}

const bannersReducer = handleActions(
  {
    GET_ALL_METADATA_REQUEST: (state) => ({
      ...state,
      metadata: {
        ...state.metadata,
        requesting: true
      }
    }),
    GET_ALL_METADATA_SUCCESS: (state) => ({
      ...state,
      metadata: {
        ...state.metadata,
        requesting: false
      }
    }),
    GET_ALL_METADATA_FAILED: (state, action) => ({
      ...state,
      metadata: {
        ...state.metadata,
        requesting: false,
        error: action.payload.errors ? action.payload.errors.map((x) => x.message).join(', ') : action.payload
      }
    }),
    UPLOADS_IMAGE_REQUEST: (state) => ({
      ...state,
      uploadsImage: {
        ...state.uploadsImage,
        requesting: true
      }
    }),
    UPLOADS_IMAGE_SUCCESS: (state, action) => ({
      ...state,
      uploadsImage: {
        ...state.uploadsImage,
        url: action.payload.list[0],
        requesting: false
      }
    }),
    UPLOADS_IMAGE_FAILED: (state, action) => ({
      ...state,
      uploadsImage: {
        ...state.uploadsImage,
        requesting: false,
        error: action.payload.errors ? action.payload.errors.map((x) => x.message).join(', ') : action.payload
      }
    }),
    SET_ALL_METADATA: (state, action) => {
      const payload = {
        data: action.payload.list,
        paging: action.payload.paging
      }
      return setMetadata(state, payload)
    },
    SET_METADATA: (state, action) => {
      const upd = action.payload
      const idx = state.metadata.data.findIndex((el) => el.mdID === upd.mdID)
      const data = state.metadata.data.map((item, i) => {
        if (i !== idx) {
          return item
        }
        return {
          ...item,
          ...upd
        }
      })
      const payload = {
        data
      }
      return setMetadata(state, payload)
    }
  },
  initialState
)

export default bannersReducer
