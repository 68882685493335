import { handleActions } from 'redux-actions'

const initialState = {
  ordersTiktok: {
    list: [],
    paging: {},
    error: null
  },
  ordersCustom: {
    list: [],
    paging: {},
    error: null
  },
  orderShopify: {
    list: [],
    paging: {},
    error: null
  },
  isLoading: {}
}


const setLoading = (state, payload, isLoading = true) => ({
  ...state,
  isLoading: {
    ...state.isLoading,
    [payload]: isLoading
  }
})

const reducer = handleActions(
  {
    TRANSACTION_LOAD_START: (state, action) => setLoading(state, action.payload, true),
    TRANSACTION_LOAD_END: (state, action) => setLoading(state, action.payload, false),
    GET_ALL_ORDERS_TIKTOK_FAILED: (state, action) => ({
      ...state,
      ordersTiktok: {
        error: action.payload.errors ? action.payload.errors.map((x) => x.message).join(', ') : action.payload
      }
    }),
    GET_ALL_ORDERS_TIKTOK_SUCCESS: (state, action) => ({
      ...state,
      ordersTiktok: {
        list: action.payload.list,
        paging: action.payload.paging
      }
    }),
    GET_ALL_ORDERS_CUSTOM_FAILED: (state, action) => ({
      ...state,
      ordersCustom: {
        error: action.payload.errors ? action.payload.errors.map((x) => x.message).join(', ') : action.payload
      }
    }),
    GET_ALL_ORDERS_CUSTOM_SUCCESS: (state, action) => ({
      ...state,
      ordersCustom: {
        list: action.payload.list,
        paging: action.payload.paging
      }
    }),
    GET_ALL_ORDERS_SHOPIFY_FAILED: (state, action) => ({
      ...state,
      orderShopify: {
        error: action.payload.errors ? action.payload.errors.map((x) => x.message).join(', ') : action.payload
      }
    }),
    GET_ALL_ORDERS_SHOPIFY_SUCCESS: (state, action) => ({
      ...state,
      orderShopify: {
        list: action.payload.list,
        paging: action.payload.paging
      }
    })
  },
  initialState
)

export default reducer
